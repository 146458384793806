























































































































































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import FetchFailure from '@/components/shared/FetchFailure.vue';
import moment from 'moment';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import { API_DATE_FORMAT } from '@/constants';
import { MomentHelper } from '@/helpers/moment.helper';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { BookingsFetchType, useCustomersStore } from '@/store/customers-store';
import { useLocationsStore } from '@/store/locations-store';
import { Customer } from '@/model/customer';
import { Booking } from '@/model/booking';
import { useBookingsStore } from '@/store/bookings-store';
import { useServicesStore } from '@/store/services-store';
import { Vendor } from '@/model/vendor';
import { useVendorsStore } from '@/store/vendors-store';
import { useMyAccountStore } from '@/store/my-account-store';

export default Vue.extend({
  components: {
    LoadingIndicator,
    EmptyDataset,
    FetchFailure,
    ConfirmModal
  },
  data() {
    return {
      currentPage: 1,
      MomentHelper,
      cancelBookingModal: {} as IConfirmModal
    };
  },
  computed: {
    ...mapStores(
      useCustomersStore,
      useLocationsStore,
      useBookingsStore,
      useServicesStore,
      useVendorsStore,
      useMyAccountStore
    ),
    customer(): Customer {
      return this.customersStore.currentEntity;
    },
    bookings(): Booking[] {
      return this.customersStore.bookings[BookingsFetchType.OPEN].entities;
    },
    rows(): number {
      return this.customersStore.bookings[BookingsFetchType.OPEN].pagination
        .count;
    },
    pageCount(): number {
      return this.customersStore.bookings[BookingsFetchType.OPEN].pagination
        .page_count;
    },
    isFetching(): boolean {
      return this.customersStore.bookings[BookingsFetchType.OPEN].fetching;
    },
    errorOccurred(): boolean {
      return this.customersStore.bookings[BookingsFetchType.OPEN].failure;
    },
    vendor(): Vendor {
      return this.vendorsStore.getById(this.myAccountStore.selectedVendorId);
    }
  },
  mounted() {
    this.cancelBookingModal = (this.$refs
      .cancelBookingModal as unknown) as IConfirmModal;
    this.fetchOpenBookings();
  },
  methods: {
    getLocationById(locationId) {
      return this.locationsStore.getById(locationId);
    },
    fetchOpenBookings() {
      this.customersStore.getBookings(this.currentPage, BookingsFetchType.OPEN);
    },
    fetchClosedBookings() {
      this.customersStore.getBookings(1, BookingsFetchType.CLOSED);
    },
    async openCancelBookingModal(booking) {
      this.cancelBookingModal.openModal(
        `Veuillez confirmer l'annulation de la réservation du ${moment(
          booking.start
        ).format('DD.MM.YYYY')} de ${moment(booking.start).format(
          'HH:mm'
        )} à ${moment(booking.end).format(
          'HH:mm'
        )} l'aide du bouton "Annuler la réservation" ci-dessous.`,
        {
          okTitle: 'Annuler la réservation',
          okVariant: 'danger',
          cancelTitle: 'Retour'
        },
        booking
      );
    },
    async onConfirmBookingCancellation(booking) {
      const bookingSuccessfullyCancelled = await this.customersStore.cancelBooking(
        booking.id
      );
      this.cancelBookingModal.sendingData = false;
      if (bookingSuccessfullyCancelled) {
        this.cancelBookingModal.closeModal();
        this.fetchOpenBookings();
        this.fetchClosedBookings();
      }
    },
    editBooking(booking) {
      this.bookingsStore.$reset();
      this.bookingsStore.setBookingInEdition(booking);
      this.bookingsStore.selectVendorId(booking.customer.vendor_id);
      this.bookingsStore.selectCustomer(booking.customer);
      // Lieu
      const location = this.locationsStore.entities.find(
        location => location.id === booking.events[0].location_id
      );
      this.bookingsStore.setLocation(location);
      // Services (tous dans le même groupe puisqu'une seule réservation)
      booking.events.forEach((event, index) => {
        const serviceEntity = this.servicesStore.getById(event.service.id);
        if (serviceEntity) {
          this.bookingsStore.addService(serviceEntity);
        }
      });
      // Selected Booking sera défini au passage dans BookingStepDate si le RDV est toujours dispo
      this.bookingsStore.setDate(moment(booking.start).format(API_DATE_FORMAT));
      this.bookingsStore.selectBooking(booking);
      this.bookingsStore.setStep(5);

      if (this.isStaffOrMore) {
        this.$router.push({
          name: 'EditBooking',
          // @ts-ignore
          params: { customer: this.customer }
        });
      } else {
        this.$router.push({
          name: 'PublicBooking',
          params: {
            vendorId: booking.events[0].vendor_id,
            locationId: booking.events[0].location_id
          }
        });
      }
    },
    isCancellable(booking: Booking) {
      if (this.isStaffOrMore) {
        return moment(booking.start).isAfter(moment());
      } else {
        // Les clients peuvent annuler jusqu'à 24h à l'avance
        const cancellationNotice = parseInt(
          this.vendor.cancellation_notice.toString(),
          10
        );
        return moment(booking.start).isAfter(
          moment().add(cancellationNotice, 'hours')
        );
      }
    }
  }
});
