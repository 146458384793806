var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"tw-flex",class:_vm.vendors.length < 2 ? 'tw-justify-end' : 'tw-justify-between tw-gap-2'},[(_vm.vendors.length > 1 && _vm.vendor)?_c('b-dropdown',{attrs:{"no-caret":_vm.vendors.length === 1,"variant":"outline-primary","text":_vm.vendor.public_name}},_vm._l((_vm.vendorsAsOptions),function(vendorOption){return _c('b-dropdown-item',{key:vendorOption.value,attrs:{"active":vendorOption.value === _vm.vendorId},on:{"click":function($event){return _vm.selectVendor(vendorOption.value)}}},[_vm._v(" "+_vm._s(vendorOption.label)+" ")])}),1):_vm._e(),(_vm.vendorId)?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
        name: 'PublicBooking',
        params: {
          vendorId: _vm.vendorId
        }
      },"exact":""}},[_c('i',{staticClass:"fas fa-calendar-plus mr-2"}),_vm._v(" "+_vm._s(_vm.$t('myAccount.makeAppointment'))+" ")]):_vm._e()],1),(_vm.vendorId)?_c('div',{staticClass:"mt-3"},[_c('h1',[_vm._v(_vm._s(_vm.$t('myAccount.account'))+" "+_vm._s(_vm.vendor.public_name))])]):_vm._e(),(_vm.notValidatedEmail)?_c('section',[_c('my-account-info-box')],1):_vm._e(),(_vm.isFetchingCustomer)?_c('loading-indicator'):_vm._e(),(!_vm.isFetchingCustomer && !_vm.isCustomerError && _vm.vendorId)?_c('section',[_c('customer-open-bookings'),_c('customer-closed-bookings'),(_vm.customer && _vm.customer.vendor && _vm.customer.vendor.fidelity_activated)?_c('customer-fidelity-vouchers'):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.vendorId)?_c('router-link',{staticClass:"btn btn-primary mt-5",attrs:{"to":{
        name: 'Home'
      },"exact":""}},[_c('i',{staticClass:"fas fa-calendar-plus mr-2 my-3"}),_vm._v(" "+_vm._s(_vm.$t('myAccount.makeFirstAppointment'))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }