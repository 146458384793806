import { BOOKING_STATUS } from '@/model/booking-status';
import i18n from '@/config/i18n';

export default function bookingStatus(bookingStatus) {
  switch (bookingStatus) {
    case BOOKING_STATUS.CANCELED:
      return i18n.t('bookingStatus.canceled');
    case BOOKING_STATUS.NO_SHOW:
      return i18n.t('bookingStatus.noShow');
    case BOOKING_STATUS.EXECUTED:
      return i18n.t('bookingStatus.executed');
    default:
      return bookingStatus;
  }
}
