import { defineStore } from 'pinia';
import { getMainCollections } from '@/store/common/utilities';

export const useMyAccountStore = defineStore('myAccount', {
  state: () => ({
    selectedVendorId: null as number | null
  }),
  actions: {
    setVendorId(id: number) {
      this.selectedVendorId = id;
      getMainCollections(id, true);
    }
  }
});
