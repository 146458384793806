











































































































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import FetchFailure from '@/components/shared/FetchFailure.vue';
import moment from 'moment';
import i18n from '@/config/i18n';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCustomersStore } from '@/store/customers-store';
import { Customer } from '@/model/customer';
import { Voucher } from '@/model/voucher';
import { Vendor } from '@/model/vendor';
import { CustomerPointsStats } from '@/model/customer-points-stats';

export default Vue.extend({
  components: {
    LoadingIndicator,
    EmptyDataset,
    FetchFailure
  },
  data() {
    return {
      currentPage: 1
    };
  },
  computed: {
    ...mapStores(useCustomersStore),
    customer(): Customer {
      return this.customersStore.currentEntity;
    },
    vendor(): Vendor {
      return this.customer.vendor;
    },
    fidelityVouchers(): Voucher[] {
      return this.customersStore.fidelityVouchers.entities;
    },
    stats(): CustomerPointsStats {
      return this.customersStore.fidelityVouchers.stats;
    },
    rows(): number {
      return this.customersStore.fidelityVouchers.pagination.count;
    },
    pageCount(): number {
      return this.customersStore.fidelityVouchers.pagination.page_count;
    },
    isFetching(): boolean {
      return this.customersStore.fidelityVouchers.fetching;
    },
    errorOccurred(): boolean {
      return this.customersStore.fidelityVouchers.failure;
    }
  },
  created() {
    this.fetchFidelityVouchers();
    this.fetchFidelityVouchersStats();
  },
  methods: {
    voucherStatus(validity) {
      const isBefore = moment(validity).isBefore(moment());
      return {
        text: isBefore
          ? i18n.t('fidelityVouchers.expired')
          : i18n.t('fidelityVouchers.valid'),
        color: isBefore ? 'danger' : 'success'
      };
    },
    fetchFidelityVouchers() {
      this.customersStore.getFidelityVouchers(
        this.customer.id,
        this.currentPage
      );
    },
    fetchFidelityVouchersStats() {
      this.customersStore.getPointsStatsForCustomer(this.customer.id);
    }
  }
});
