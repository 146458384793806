
































import MyAccountView from '@/components/my-account/MyAccountView.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import FetchFailure from '@/components/shared/FetchFailure.vue';
import MyAccountEmployeeTabs from '@/components/my-account/MyAccountEmployeeTabs.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { useVendorsStore } from '@/store/vendors-store';
import { useMyAccountStore } from '@/store/my-account-store';
import { Vendor } from '@/model/vendor';
import { User } from '@/model/user';
import { useCustomersStore } from '@/store/customers-store';

export default Vue.extend({
  components: {
    MyAccountView,
    LoadingIndicator,
    FetchFailure,
    MyAccountEmployeeTabs
  },
  data() {
    return {
      unsubscribeFromStore: null as any
    };
  },
  computed: {
    ...mapStores(
      useUsersStore,
      useVendorsStore,
      useMyAccountStore,
      useCustomersStore
    ),
    loggedInUserId(): number {
      return this.usersStore.loggedInUserId as number;
    },
    vendors(): Vendor[] {
      return this.vendorsStore.entities;
    },
    isFetchingVendors(): boolean {
      return this.vendorsStore.fetching;
    },
    isVendorError(): boolean {
      return this.vendorsStore.failure;
    },
    user(): User {
      return this.usersStore.user.entity;
    },
    selectedVendorId(): number {
      return this.myAccountStore.selectedVendorId as number;
    },
    timetableEnabledForLoggedInUser(): boolean {
      return this.user.employee ? this.user.employee.timetable : false;
    }
  },
  async created() {
    if (this.isCustomer) {
      await this.getUserVendors();

      this.unsubscribeFromStore = this.myAccountStore.$onAction(
        ({ name, after }) => {
          after(() => {
            if (name === 'setVendorId') {
              this.getCustomer();
            }
          });
        }
      );

      if (this.vendors.length > 0) {
        await this.setVendorId(this.vendors[0].id);
      }
    }
  },
  destroyed() {
    if (this.unsubscribeFromStore) {
      this.unsubscribeFromStore();
    }
  },
  methods: {
    async getUserVendors() {
      await this.vendorsStore.getUserVendorsList(this.loggedInUserId);
    },
    getCustomer() {
      this.customersStore.getAccount(
        this.loggedInUserId,
        this.selectedVendorId
      );
    },
    setVendorId(vendorId: number) {
      return this.myAccountStore.setVendorId(vendorId);
    },
    logout() {
      this.usersStore.logout();
    }
  }
});
