























































































































































































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import FetchFailure from '@/components/shared/FetchFailure.vue';
import bookingStatus from '@/filters/booking-status';
import { BOOKING_STATUS } from '@/model/booking-status';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import moment from 'moment';
import customerService from '@/services/customer.service';
import { MomentHelper } from '@/helpers/moment.helper';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { BookingsFetchType, useCustomersStore } from '@/store/customers-store';
import { Customer } from '@/model/customer';
import { Booking } from '@/model/booking';
import { useLocationsStore } from '@/store/locations-store';
import { AlertType, useUiStore } from '@/store/ui-store';
import { CustomerBookingsStats } from '@/model/customer-bookings-stats';
import { Location } from '@/model/location';

export default Vue.extend({
  filters: {
    bookingStatus
  },
  components: {
    LoadingIndicator,
    EmptyDataset,
    FetchFailure,
    ConfirmModal
  },
  data() {
    return {
      currentPage: 1,
      BOOKING_STATUS: BOOKING_STATUS,
      MomentHelper,
      deleteBookingModal: {} as IConfirmModal
    };
  },
  computed: {
    ...mapStores(useUiStore, useCustomersStore, useLocationsStore),
    customer(): Customer {
      return this.customersStore.currentEntity;
    },
    bookings(): Booking[] {
      return this.customersStore.bookings[BookingsFetchType.CLOSED].entities;
    },
    rows(): number {
      return this.customersStore.bookings[BookingsFetchType.CLOSED].pagination
        .count;
    },
    pageCount(): number {
      return this.customersStore.bookings[BookingsFetchType.CLOSED].pagination
        .page_count;
    },
    isFetching(): boolean {
      return this.customersStore.bookings[BookingsFetchType.CLOSED].fetching;
    },
    errorOccurred(): boolean {
      return this.customersStore.bookings[BookingsFetchType.CLOSED].failure;
    },
    stats(): CustomerBookingsStats {
      return this.customersStore.bookings[BookingsFetchType.CLOSED].stats;
    }
  },
  mounted() {
    this.deleteBookingModal = (this.$refs
      .deleteBookingModal as unknown) as IConfirmModal;
    this.fetchClosedBookingsAndStats();
  },
  methods: {
    getLocationById(locationId: number): Location {
      return this.locationsStore.getById(locationId);
    },
    fetchClosedBookingsAndStats() {
      this.customersStore.getBookings(
        this.currentPage,
        BookingsFetchType.CLOSED
      );

      if (this.isStaffOrMore) {
        this.customersStore.getBookingsStatsForCustomer(this.customer.id);
      }
    },
    openDeleteBookingModal(booking: Booking) {
      this.deleteBookingModal.openModal(
        `Veuillez confirmer la suppression du RDV du ${moment(
          booking.start
        ).format('DD.MM.YYYY')} débutant à ${moment(booking.start).format(
          'HH:mm'
        )} à l'aide du bouton "Supprimer" ci-dessous`,
        {
          okTitle: 'Supprimer',
          okVariant: 'danger'
        },
        booking
      );
    },
    async deleteBooking(booking) {
      try {
        const bookingDeleted = await customerService.deleteBooking(booking.id);
        this.deleteBookingModal.sendingData = false;
        if (bookingDeleted) {
          this.deleteBookingModal.closeModal();
          this.uiStore.alert('Réservation supprimée.', AlertType.SUCCESS);
          this.fetchClosedBookingsAndStats();
        }
      } catch (exception) {
        this.deleteBookingModal.sendingData = false;
      }
    }
  }
});
