













import userService from '@/services/user.service';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { User } from '@/model/user';
import { AlertType, useUiStore } from '@/store/ui-store';

export default Vue.extend({
  computed: {
    ...mapStores(useUsersStore, useUiStore),
    user(): User {
      return this.usersStore.user.entity;
    },
    loggedInUserId(): number {
      return this.usersStore.loggedInUserId as number;
    }
  },
  methods: {
    getUser() {
      return this.usersStore.getUser(this.loggedInUserId);
    },
    async sendMail() {
      await this.getUser();

      try {
        const data = await userService.sendValidationEmail(this.user.email);
        this.uiStore.alert(data.message, AlertType.SUCCESS);
      } catch (exception) {
        this.uiStore.alertValidationError(exception);
      }
    }
  }
});
