






































































import CustomerOpenBookings from '@/components/customers/view/CustomerOpenBookings.vue';
import CustomerClosedBookings from '@/components/customers/view/CustomerClosedBookings.vue';
import CustomerFidelityVouchers from '@/components/customers/view/CustomerFidelityVouchers.vue';
import MyAccountInfoBox from '@/components/my-account/MyAccountInfoBox.vue';
import Vue from 'vue';
import { Vendor } from '@/model/vendor';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { useCustomersStore } from '@/store/customers-store';
import { useVendorsStore } from '@/store/vendors-store';
import { useMyAccountStore } from '@/store/my-account-store';
import { InputOption } from '@/model/input-option';
import { Customer } from '@/model/customer';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';

export default Vue.extend({
  components: {
    CustomerFidelityVouchers,
    CustomerOpenBookings,
    CustomerClosedBookings,
    MyAccountInfoBox,
    LoadingIndicator
  },
  data() {
    return {
      unsubscribeFromStore: null as any
    };
  },
  computed: {
    ...mapStores(
      useUsersStore,
      useCustomersStore,
      useVendorsStore,
      useMyAccountStore
    ),
    loggedInUserId(): number {
      return this.usersStore.loggedInUserId as number;
    },
    notValidatedEmail(): boolean {
      return this.usersStore.loggedInUserNotValidatedEmail;
    },
    vendorsAsOptions(): InputOption[] {
      return this.vendorsStore.getAllAsSelectOptions;
    },
    customer(): Customer {
      return this.customersStore.currentEntity;
    },
    isFetchingCustomer(): boolean {
      return this.customersStore.fetching;
    },
    isCustomerError(): boolean {
      return this.customersStore.failure;
    },
    vendors(): Vendor[] {
      return this.vendorsStore.entities;
    },
    vendorId(): number | null {
      return this.myAccountStore.selectedVendorId;
    },
    vendor(): Vendor | null {
      if (this.vendorId) {
        const vendor = this.vendorsStore.getById(this.vendorId);
        if (vendor) {
          return vendor;
        }
        return null;
      }
      return null;
    }
  },
  methods: {
    getCustomer(): Promise<Customer | null> {
      return this.customersStore.getAccount(
        this.loggedInUserId,
        this.vendorId as number
      );
    },
    getUserVendors() {
      return this.vendorsStore.getUserVendorsList(this.loggedInUserId);
    },
    selectVendor(vendorId: number) {
      this.myAccountStore.setVendorId(vendorId);
    }
  }
});
